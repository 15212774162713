import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from "gatsby";
import Flickity from "react-flickity-component";

import Layout from "../components/layout";
import { BackgroundSection } from "../components/primitives";
import { DisplayHeader } from '../components/composites';
import { GatsbyLink, countryRoute } from "../utils";

import './countries.scss'

const flickityOptions = {
    freeScroll: false,
    prevNextButtons: false,
    pageDots: false,
    imagesLoaded: true,
    cellAlign: 'left'
};


const CountriesPage = ({data, location}) => {
    const {
        directus: {
            countries
        }
    } = data;

    return(
        <Layout className='country-page'
                title='All Countries | Explore Wineries Worldwide'
                currentPage='countries'
                location={location}
                description='Wineries offer so many different experiences check them out'
                image={null}
        >
            <DisplayHeader
                title={'All Countries'}
                location={location}
            >
                <section className='all-tags-section'>
                    <div className='shell'>
                        <div className='section-body'>
                            <ul className='list-search-result'>
                                {countries.map( ({description, id, name, slug, featured_image, wineries}, index) => (
                                    <li key={`list-item-${id}`}>
                                        <div className="search-result-item">
                                            <div className="search-result-item-inner">
                                                <Flickity
                                                    className={'search-result-item-slider'}
                                                    options={flickityOptions}
                                                    reloadOnUpdate={true}
                                                >
                                                    <div className="slide" key={index + name}>
                                                        <BackgroundSection
                                                            className='slide-image'
                                                            image={featured_image ?? ''}
                                                            isGatsbyImage
                                                        >
                                                            <GatsbyLink href={countryRoute(slug)}>
                                                                <span className='sr-only'>View {name}</span>
                                                            </GatsbyLink>
                                                        </BackgroundSection>
                                                    </div>
                                                </Flickity>
                                            </div>

                                            <div className="search-result-item-content">
                                                <GatsbyLink href={countryRoute(slug)}><span className='sr-only'>View {name}</span></GatsbyLink>
                                                <div className="search-result-item-title">{name} <GatsbyLink href={countryRoute(slug)} className='see-more'>View all {wineries.length} wineries</GatsbyLink></div>
                                                <p className='search-result-item-text'>{description}</p>

                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </DisplayHeader>
        </Layout>
    )
}

CountriesPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

CountriesPage.defaultProps = {

}

export const TagsQuery = graphql`
  query {
    directus {
      countries(
        limit: -1
        sort: ["name"]
        filter: {wineries: {id: {_nnull: true}}, status: {_eq: "published"}}
      ) {
        id
        name
        slug
        description
        wineries {
            id
        }
        featured_image {
          id
          imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
       }
    }
  }
`

export default CountriesPage